import { gql } from 'apollo-boost';
import CarouselNew from 'core/components/carousel-new/CarouselNew';
import CenterLoader from 'core/components/common/CenterLoader';
import { useDeviceInfo } from 'core/components/DeviceInfoContext';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { FC } from 'react';
import AdvertisingCourse from 'site/components/product-form/AdvertisCourse';
import ProductFormCard from 'site/components/product-form/ProductFormCard';
import Swipe from 'site/components/swipe/SwipeIcon';
import { PagePublicTypeEnum, ProductFormOrderEnum, ProductFormPublicTypeEnum, useMainCoursesQuery } from 'site/graphql';
import { useAuth } from '../auth/AuthProvider';
import c from 'classnames';

gql`
    query MainCourses($pageFilter: PageListRequest, $productFormFilter: ProductFormListRequest) {
        pages(filter: $pageFilter) {
            count
            result {
                id
                title
                slug
                content
                shortDescription
                type
            }
        }
        productForms(filter: $productFormFilter) {
            count
            result {
                id
                type
                price
                title
                maxOrders
                ordersCount
                paymentStatus
                requiredFieldsNumber
                showRemainingOrders
                showNumberLessons
                pictureId
                order {
                    id
                }
                product {
                    id
                    name
                    shortDescription
                    pictureId
                    offer
                    contentProvider {
                        id
                        offer
                    }
                    lecturers {
                        id
                        account {
                            id
                            firstName
                            lastName
                        }
                    }
                }
                withMeInSetForms {
                    id
                    type
                }
                webinars {
                    id
                    date
                    soonCause
                }
                seminars {
                    id
                    date
                    soonCause
                }
                records {
                    id
                    date
                }
            }
        }
    }
`;

const slugs = ['promo-course-1', 'promo-course-2', 'promo-course-3', 'promo-course-4'];

const MainCourses: FC<WithStyles<typeof styles>> = ({ classes }) => {
    const { isLoggedIn } = useAuth();
    const { isMobile } = useDeviceInfo();

    const { loading, data } = useMainCoursesQuery({
        fetchPolicy: 'network-only',
        variables: {
            pageFilter: { type: PagePublicTypeEnum.TextBlock, slugs },
            productFormFilter: {
                types: [ProductFormPublicTypeEnum.Seminar, ProductFormPublicTypeEnum.Webinar],
                take: 10,
                order: ProductFormOrderEnum.StartDate,
                contentProviderId: 1,
                showPublic: true,
                onlyAvailable: true,
                onlyWithPlaces: true,
            },
        },
    });

    if (loading) {
        return <CenterLoader />;
    }

    // Разбор ответа
    const {
        productForms: { result: productForms },
        pages: { result: pages },
    } = data;
    const promoCourseOne = pages.find(page => page.slug === 'promo-course-1');
    const promoCourseTwo = pages.find(page => page.slug === 'promo-course-2');
    const promoCourseThree = pages.find(page => page.slug === 'promo-course-3');
    const promoCourseFour = pages.find(page => page.slug === 'promo-course-4');

    return (
        <>
            {isLoggedIn ? (
                <div className={classes.containerCarousel}>
                    <Swipe />
                    <CarouselNew naturalSlideHeight={1.1} isPlaying={false}>
                        {!isMobile && promoCourseOne && <AdvertisingCourse slug="promo-course-1" inCarousel />}
                        {productForms.map(productForm => (
                            <ProductFormCard
                                key={productForm.id}
                                productForm={productForm}
                                mode="MAIN_PAGE"
                                isDark={false}
                            />
                        ))}
                    </CarouselNew>
                </div>
            ) : (
                // при размере экрана > 1170 'lg' лист вместо карусели
                <>
                    <div className={c(classes.containerCarousel, classes.unLoginContainerCarousel)}>
                        <Swipe />
                        <CarouselNew naturalSlideHeight={1.1} isPlaying>
                            {promoCourseOne && (
                                <AdvertisingCourse className={classes.card} slug="promo-course-1" inCarousel />
                            )}
                            {promoCourseTwo && (
                                <AdvertisingCourse className={classes.card} slug="promo-course-2" inCarousel />
                            )}
                            {promoCourseFour && (
                                <AdvertisingCourse className={classes.card} slug="promo-course-4" inCarousel />
                            )}
                            {promoCourseThree && (
                                <AdvertisingCourse className={classes.card} slug="promo-course-3" inCarousel />
                            )}
                        </CarouselNew>
                    </div>

                    <div className={classes.promoCourses}>
                        {promoCourseOne && <AdvertisingCourse className={classes.card} slug="promo-course-1" />}
                        {promoCourseTwo && <AdvertisingCourse className={classes.card} slug="promo-course-2" />}
                        {promoCourseFour && <AdvertisingCourse className={classes.card} slug="promo-course-4" />}
                        {promoCourseThree && <AdvertisingCourse className={classes.card} slug="promo-course-3" />}
                    </div>
                </>
            )}
        </>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        containerCarousel: {
            height: '100%',
        },

        unLoginContainerCarousel: {
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
            [theme.breakpoints.down('md')]: {
                display: 'block',
            },
        },

        promoCourses: {
            display: 'flex',
            justifyContent: 'center',
            [theme.breakpoints.up('md')]: {
                display: 'flex',
            },
            [theme.breakpoints.down('md')]: {
                display: 'none',
            },
            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
            },
        },

        card: {
            minWidth: 250,
            margin: '0 10px',
            '&:nth-child(1)': {
                margin: '0 10px 0 0',
            },
            '&:nth-child(4)': {
                margin: '0 0 0 10px',
            },
        },
    });

export default injectSheet(styles)(MainCourses);
