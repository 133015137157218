import { gql } from 'apollo-boost';
import { getOriginalPictureUrl } from 'core/api/pictures';
import CarouselNew from 'core/components/carousel-new/CarouselNew';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React, { FC } from 'react';
import { useGetPartnersQuery } from 'site/graphql';

gql`
    query GetPartners {
        partners {
            id
            name
            link
            position
            pictureId
        }
    }
`;

const Partners: FC<WithStyles<typeof styles>> = ({ classes }) => {
    const { data, loading } = useGetPartnersQuery();

    if (loading || !data || data.partners.length === 0) {
        return null;
    }

    const sorted = data.partners.slice().sort((a, b) => a.position - b.position);

    return (
        <div className={classes.container}>
            <CarouselNew naturalSlideHeight={0.2}>
                {sorted.map(p => (
                    <a key={p.id} href={p.link} title={p.name} target="_blank" rel="noopener noreferrer">
                        <img src={getOriginalPictureUrl(p.pictureId, null)} className={classes.slide} />
                    </a>
                ))}
            </CarouselNew>
        </div>
    );
};

const styles = () =>
    createStyles({
        container: {
            margin: '50px 0',
        },
        slide: {
            width: 100 + '%',
            height: 'calc(100% + 20px)', // CarouselNew padding 10px top/bottom
            filter: 'grayscale(100%)',
            opacity: 0.6,
            transition: 'filter 0.25s, opacity 0.25s',
            marginTop: -10,
            objectFit: 'contain',

            '&:hover, &:focus': {
                opacity: 1,
                filter: 'grayscale(0%)',
            },
        },
    });

export default injectSheet(styles, 'Partners')(Partners);
